
import { Component, Prop, Vue } from "vue-property-decorator";
import FormCard from "@/components/FormCard.vue";
import LogoSingle from "@/components/global/LogoSingle.vue";
import ForgottenPasswordForm from "@/components/forms/ForgottenPasswordForm.vue";

@Component({
  components: {
    FormCard,
    LogoSingle,
    ForgottenPasswordForm
  }
})
export default class ForgottenPassword extends Vue {}
