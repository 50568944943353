
import { Component, Prop, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
interface restoreData {
  document_type: string;
  document_id: string;
}

@Component
export default class ForgottenPasswordForm extends Mixins(FormValidator) {
  @Prop({ default: false }) readonly quick_login!: boolean;
  options: object[] = [
    { label: "DNI", value: "0" },
    { label: "RUT", value: "1" },
    { label: "Pasaporte", value: "2" }
  ];
  protected formData: restoreData = {
    document_type: "1",
    document_id: ""
  };

  private credentials_found = true;
  private user_email = "";
  private confirm = false;

  private confirmEmail(): boolean {
    this.$axios
      .post("personas/recuperar/contrasena/email", {
        tipo_documento: Number(this.formData.document_type),
        rut: this.formData.document_id
      })
      .then(response => {
        this.user_email = response.data.response;
        this.confirm = true;
      })
      .catch(error => {
        this.setCredentialsNotFound();
      });
    return true;
  }

  private confirmReset(): boolean {
    this.$axios
      .post("personas/recuperar/contrasena/request", {
        tipo_documento: Number(this.formData.document_type),
        rut: this.formData.document_id
      })
      .then(response => {
        this.$router.push({ name: "home" });
        this.$q.notify({
          message: this.$t("notification.email_reset_password") as string,
          color: "accent",
          position: "top",
          timeout: 7500
        });
      })
      .catch(error => {
        this.$router.push({ name: "home" });
        this.$q.notify({
          message: this.$t("notification.email_reset_error") as string,
          color: "accent",
          position: "top",
          timeout: 7500
        });
      });
    return true;
  }

  private setCredentialsNotFound() {
    this.credentials_found = false;
    (this.$refs.form as any).validate();
  }

  private credentialsFound() {
    if (this.credentials_found) {
      return true;
    } else {
      return this.$t("validation.user_not_found");
    }
  }
}
